table {
  width: 100%;
}
.mat-row:nth-child(even){
  background-color: #FFFFFF;
}
  
.mat-row:nth-child(odd){
  background-color:#F6FBFF;
}

